<template>
  <div class="bg-[#262653] text-white p-3 overflow-y-scroll h-[88dvh]">
    <ul>
      <li
        v-for="(category, index) in getCategoriesData"
        :key="index"
        class="flex items-center mx-6 my-8 space-x-3 mx"
      >
        <div class="bg-[#313169] rounded-full size-10">
          <img 
          v-lazy="category.category_img"
          :alt="category.name" class="mt-1 ml-1 size-8" />
        </div>
        <span class="hover:text-[#484892]"
          ><router-link :to="`/category/show/${category.id}`">{{ category.name }}</router-link></span
        >
      </li>
    </ul>
  </div>
</template>

<script>
import { items } from '@/fakers/menu'
import { useCategoryStore } from '@/store/category';
import { mapState,mapActions } from 'pinia';
export default {
  data () {
    return {
      items: items
    }
  },
  computed:{
    ...mapState(useCategoryStore,['getCategoriesData'])
  },
  methods:{
    ...mapActions(useCategoryStore,['fetchCategories'])
  },
  async created(){
    await this.fetchCategories();
  }
}
</script>

<style></style>
