<template>
  <!-- component -->
  <div class="relative md:mr-6 my-2 md:w-[400px] w-[90dvw]">
    <input
      type="search"
      v-model="query"
      @keyup.enter="fetchSearch"
      class="bg-purple-white shadow text-center md:border-0 border border-gray-200 hover:border-gray-50 text-white p-3 bg-[#262653] md:w-10/12 w-full rounded-[25px]"
      :placeholder="$t('searchBy')"
    />
    <div class="absolute ml-4 pin-r pin-t top-3 text-purple-lighter" :class="isRtlLayout ? 'mr-4' : 'ml-4'">
      <search-svg class="cursor-pointer" @click="fetchSearch" />
    </div>
  </div>
</template>

<script>
import SearchSvg from '../svg/SearchSvg.vue'
import { useSearchStore } from '@/store/search'
import { mapActions, mapState } from 'pinia'

export default {
  inject:["isRtlLayout"],
  components: { SearchSvg },
  data () {
    return {
      query: ''
    }
  },
  computed: {
    ...mapState(useSearchStore, ['getSearchResults'])
  },
  methods: {
    ...mapActions(useSearchStore, ['fetchSearchData']),
    async fetchSearch () {
      if (this.query == '') return
      await this.fetchSearchData(this.query).then(response => {
        if (response.data) {
          this.query = ''
          this.$router.push('/search/results')
        }
      })
    }
  }
}
</script>

<style></style>
