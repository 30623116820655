
const routes = [
    {
        path: '/',
        name: 'home',
        meta: {
            title: 'Home',
            description: 'Explore the Mars gaming platform and start wonderful games!',
        },      
        component: () => import(/* webpackChunkName: "category" */ '../views/pages/HomeView.vue')

    },
    {
        path: '/category/show/:id',
        name: 'category.show',
        meta: {
            title: 'Category',
            description: 'Discover categories of games and content on Mars.',
        },      
        component: () => import(/* webpackChunkName: "category" */ '../views/pages/ShowCategory.vue')
    },
    {
        path: '/game/show/:id',
        name: 'game.show',
        meta: {
            title: 'Game',
            description: 'Dive into an exciting game experience on Mars.',
        },      
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "game" */ '../views/pages/ShowGame.vue')
    },
    {
        path: '/search/results',
        name: 'search.results',
        meta: {
            title: 'Search Results',
            description: 'Find the best gaming titles, tailored to your interests. Explore detailed reviews, top picks, and trending games all in one place!',
        },      
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "game" */ '../views/pages/SearchResults.vue')
    },
    {
        path: '/user/login',
        name: 'user.login',
        meta: {
            title: 'Login',
            description: 'Log in to your Mars account and continue the fun!',
        },      
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "login" */ '../views/user/LoginView.vue')
    },
    {
        path: '/user/register',
        name: 'user.register',
        meta: {
            title: 'Register',
            description: 'Create your Mars account to access amazing features!',
        },      
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "register" */ '../views/user/RegisterView.vue')
    },
    {
        path: '/contacts',
        name: 'contacts',
        meta: {
            title: 'Contact US',
            description: 'Get in touch with us for questions, support, or feedback. We\'re here to help! Reach out through our contact form, email, or phone.',
        },      
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "contacts" */ '../views/pages/ContactUs.vue')
    },
    {
        path: '/about',
        name: 'about',
        meta: {
            title: 'About US',
            description: 'Learn more about our mission, values, and the team behind our platform. Discover how we’re committed to delivering exceptional service and experiences to our users.',
        },      
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/pages/AboutUs.vue')
    },
    {
        path: '/leaderboard',
        name: 'leaderboard',
        component: () => import(/* webpackChunkName: "leaderboard" */ '../views/pages/Leaderboard.vue'),
        meta: { 
            title: 'Leaderboard',
            description: 'Check out the top players on our leaderboard! See rankings, scores, and stats, and compete to climb the ranks in our exciting challenges.',
            requiresAuth: false
        },
    },
    {
        path: '/terms',
        name: 'terms',
        meta: {
            title: 'Terms and Conditions',
            description: 'Review our terms and conditions to use Mars responsibly.',
        },      
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "terms" */ '../views/pages/TermsView.vue')
    },
    {
        path: '/policy',
        name: 'policy',
        meta: {
            title: 'Policy',
            description: 'Read our policies to understand how Mars works.',
        },      
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "policy" */ '../views/pages/PolicyView.vue')
    },
    {
        path: '/subscribe',
        name: 'subscribe.page',
        meta: {
            title: 'Subscribe',
            description: '',
        },      
        component: () => import('../views/pages/Subscribe.vue')
    },
    {
        path: '/unsubscribe',
        name: 'unsubscribe.page',
        component: () => import('../views/pages/Unsubscribe.vue'),
        meta: {
            requiresAuth: true,
            title: 'Unsubscribe',
            description: '',
        },      
    },
    // Profile.vue
    {
        path: '/user/profile',
        name: 'user.profile',
        component: () => import('../views/user/Profile.vue'),
        meta: { 
            title: 'Profile',
            description: 'View and edit your profile details on Mars.',
            requiresAuth: true
        },
    },
    {
        path: '/:pathMatch(.*)*',
        meta: {
            title: 'Not Found',
            description: '',
        },
        component: () => import(/* webpackChunkName: "policy" */ '../views/pages/NotFound.vue')

    }
]

export default routes