<!-- RightMenu.vue -->
<template>
  <div @click="toggleRightSidebar" class="cursor-pointer">
    <!-- Your right menu option goes here -->
    <!-- For example, using Font Awesome for an icon -->
    <ellipsis-vertical/>
  </div>
</template>

<script>
import EllipsisVertical from '../svg/EllipsisVertical.vue'
export default {
  components: { EllipsisVertical },
  methods: {
    toggleRightSidebar () {
      // Emit an event to the parent component to handle right sidebar toggling
      this.$emit('toggle-right-sidebar')
    }
  }
}
</script>
