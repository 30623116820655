<!-- MobileLayout.vue -->
<template>
  <div class="flex justify-between p-[1rem] bg-[#262653] fixed inset-x-0 z-50">
    <!-- Left Sidebar -->
    <div
      v-if="showLeftSidebar"
      class="fixed start-0 top-[78px] bottom-0 bg-[#262653] z-50 animate-in  duration-700"
      :class="isRtlLayout ? 'slide-in-from-right' : 'slide-in-from-left'"
      style="width: 50%;"
    >
      <!--  left sidebar content goes here -->
      <side-bar @click="closeAll"/>
    </div>

    <!-- Left Hamburger Menu -->
    <HamburgerMenu @toggle-left-sidebar="toggleLeftSidebar" />

    <!-- Center Logo -->
    <LogoView  class="cursor-pointer" @click="$router.push({name:'home'})"/>

    <!-- Right Sidebar -->
    <div
      v-if="showRightSidebar"
      class="fixed end-0 top-[78px] bottom-0 bg-[#262653] z-50 animate-in duration-700"
      :class="isRtlLayout ? 'slide-in-from-left' : 'slide-in-from-right'"
      style="width: 50%;"
    >
      <!--  right sidebar content goes here -->

      
      <div class="flex items-center justify-center mt-12 ">
        <search-view/>
      </div>
      <rs-bar @click="closeAll" />
    </div>

    <!-- Right Menu Option -->
    <RightMenu @toggle-right-sidebar="toggleRightSidebar" />
  </div>
</template>

<script>
import { useLanguageStore } from '@/store/language'
import SearchView from '../Shared/SearchView.vue'
import SideBar from '../Shared/SideBar.vue'
import HamburgerMenu from './HamburgerMenu.vue'
import LogoView from './LogoView.vue'
import RightMenu from './RightMenu.vue'
import RsBar from './RsBar.vue'
import { mapState } from 'pinia'

export default {
  components: {
    HamburgerMenu,
    LogoView,
    RightMenu,
    SideBar,
    SearchView,
    RsBar
  },
  data () {
    return {
      showLeftSidebar: false,
      showRightSidebar: false
    }
  },
  computed: {
    ...mapState(useLanguageStore, ["isRtlLayout"]),
  },
  methods: {
    toggleLeftSidebar () {
      this.showRightSidebar=false
      this.showLeftSidebar = !this.showLeftSidebar

    },
    toggleRightSidebar () {
      this.showLeftSidebar=false
      this.showRightSidebar = !this.showRightSidebar
    },
    closeAll(){
      this.showLeftSidebar=false
      this.showRightSidebar=false
    }

  },
  watch: {
    // eslint-disable-next-line
    $route(to, from) {
      // Close all sidebars when the route changes
      this.closeAll();
    }
  },
  beforeRouteLeave(to, from, next) {
    // Close all sidebars when leaving the current route
    this.closeAll();
    next();
  },
  beforeRouteUpdate(to, from, next) {
    // Close all sidebars when updating the route
    this.closeAll();
    next();
  }
};
</script>
